<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="6" xl="6" lg="8" md="12" sm="12" class="pa-5">
                <v-row>
                    <v-col class="py-1" cols="12" md="12">
                        <Separator :text="$t('users:basicInformation')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-1" cols="6" md="6" xs="12"
                        ><v-text-field
                            :value="getUsersStaffDetails.contract"
                            readonly
                            :label="$t('users:contract')"
                        ></v-text-field
                    ></v-col>
                    <v-col class="py-1" cols="6" md="6" xs="12"
                        ><v-text-field
                            :value="getUsersStaffDetails.dayJob"
                            readonly
                            :label="$t('users:dayJob')"
                        ></v-text-field
                    ></v-col>
                </v-row>
                <v-row>
                    <v-col class="py-1" cols="6" md="6" xs="12"
                        ><v-text-field
                            :value="
                                getUsersStaffDetails.employmentStart
                                    ? $moment(
                                          getUsersStaffDetails.employmentStart
                                      ).format('YYYY-MM-DD')
                                    : ''
                            "
                            readonly
                            :label="$t('users:employmentStart')"
                        ></v-text-field
                    ></v-col>
                    <v-col class="py-1" cols="6" md="6" xs="12"
                        ><v-text-field
                            :value="
                                getUsersStaffDetails.employmentEnd
                                    ? $moment(
                                          getUsersStaffDetails.employmentEnd
                                      ).format('YYYY-MM-DD')
                                    : ''
                            "
                            readonly
                            :label="$t('users:employmentEnd')"
                        ></v-text-field
                    ></v-col>
                </v-row>
                <v-row>
                    <v-col class="py-1" cols="6" md="6" xs="12"
                        ><v-text-field
                            :value="
                                getUsersStaffDetails.lastExamination
                                    ? $moment(
                                          getUsersStaffDetails.lastExamination
                                      ).format('YYYY-MM-DD')
                                    : ''
                            "
                            readonly
                            :label="$t('users:lastExamination')"
                        ></v-text-field
                    ></v-col>
                    <v-col class="py-1" cols="6" md="6" xs="12"
                        ><v-text-field
                            :value="
                                getUsersStaffDetails.endExamination
                                    ? $moment(
                                          getUsersStaffDetails.endExamination
                                      ).format('YYYY-MM-DD')
                                    : ''
                            "
                            readonly
                            :label="$t('users:endExamination')"
                        ></v-text-field
                    ></v-col>
                </v-row>
                <v-row>
                    <v-col class="py-1" cols="6" md="6" xs="12"
                        ><v-text-field
                            :value="
                                getUsersStaffDetails.startBhp
                                    ? $moment(
                                          getUsersStaffDetails.startBhp
                                      ).format('YYYY-MM-DD')
                                    : ''
                            "
                            readonly
                            :label="$t('users:startBhp')"
                        ></v-text-field
                    ></v-col>
                    <v-col class="py-1" cols="6" md="6" xs="12"
                        ><v-text-field
                            :value="
                                getUsersStaffDetails.endBhp
                                    ? $moment(
                                          getUsersStaffDetails.endBhp
                                      ).format('YYYY-MM-DD')
                                    : ''
                            "
                            readonly
                            :label="$t('users:endBhp')"
                        ></v-text-field
                    ></v-col>
                </v-row>
                <v-row>
                    <v-col class="py-1" cols="6" md="6" xs="12"
                        ><v-text-field
                            :value="
                                getUsersStaffDetails.dayOfBirth
                                    ? $moment(
                                          getUsersStaffDetails.dayOfBirth
                                      ).format('YYYY-MM-DD')
                                    : ''
                            "
                            readonly
                            :label="$t('users:dayOfBirth')"
                        ></v-text-field
                    ></v-col>
                    <v-col class="py-1" cols="6" md="6" xs="12"
                        ><v-text-field
                            :value="getUsersStaffDetails.placeOfBirth"
                            readonly
                            :label="$t('users:placeOfBirth')"
                        ></v-text-field
                    ></v-col>
                </v-row>
                <v-divider class="details__devider" vertical></v-divider>
            </v-col>
            <v-col
                cols="6"
                xl="6"
                lg="8"
                md="12"
                sm="12"
                class="pa-5 details__card"
            >
                <v-row>
                    <v-col class="py-1" cols="12" md="12">
                        <Separator :text="$t('users:additionalInformation')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-1" cols="6" md="12" xs="12"
                        ><v-textarea
                            :value="getUsersStaffDetails.needments"
                            readonly
                            no-resize
                            rows="3"
                            :label="$t('users:needments')"
                        ></v-textarea
                    ></v-col>
                    <v-col class="py-1" cols="6" md="12" xs="12"
                        ><v-textarea
                            :value="getUsersStaffDetails.comment"
                            readonly
                            no-resize
                            rows="3"
                            :label="$t('users:comments')"
                        ></v-textarea
                    ></v-col>
                </v-row>
            </v-col>
        </v-row>
        <Buttons />
    </v-container>
</template>

<script>
import Buttons from './../Buttons/Buttons'
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['users'] },
    data() {
        return {}
    },

    computed: {
        ...mapGetters(['getUsersStaffDetails']),
    },

    components: {
        Buttons,
    }
}
</script>
