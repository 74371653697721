<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="e1" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getUsersStaffError.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('users:basicInformation')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getUsersStaffError.stepTwo.validation,
                                ]"
                                editable
                                step="2"
                                >{{
                                    $t('users:additionalInformation')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="$t('users:basicInformation')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-select
                                            data-cy="contract"
                                            :items="
                                                getRegistryDictionaries.contract
                                                    ? getRegistryDictionaries.contract
                                                    : []
                                            "
                                            :label="$t('users:contract')"
                                            v-model="
                                                getUsersStaffModal.contract
                                            "
                                            item-value="field"
                                        >
                                            <template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select
                                            data-cy="dayJob"
                                            :items="
                                                getRegistryDictionaries.dayJob
                                                    ? getRegistryDictionaries.dayJob
                                                    : []
                                            "
                                            :label="$t('users:dayJob')"
                                            v-model="getUsersStaffModal.dayJob"
                                            item-value="field"
                                        >
                                            <template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-menu
                                            v-model="dataOne"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    clearable
                                                    @click:clear="
                                                        getUsersStaffModal.employmentStart = null
                                                    "
                                                    v-model="
                                                        getUsersStaffModal.employmentStart
                                                    "
                                                    :label="
                                                        $t(
                                                            'users:employmentStart'
                                                        )
                                                    "
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="
                                                    getUsersStaffModal.employmentStart
                                                "
                                                @input="dataOne = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-menu
                                            v-model="dataTwo"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    clearable
                                                    @click:clear="
                                                        getUsersStaffModal.employmentEnd = null
                                                    "
                                                    v-model="
                                                        getUsersStaffModal.employmentEnd
                                                    "
                                                    :label="
                                                        $t(
                                                            'users:employmentEnd'
                                                        )
                                                    "
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="
                                                    getUsersStaffModal.employmentEnd
                                                "
                                                @input="dataTwo = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-menu
                                            v-model="dataThree"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    clearable
                                                    @click:clear="
                                                        getUsersStaffModal.lastExamination = null
                                                    "
                                                    v-model="
                                                        getUsersStaffModal.lastExamination
                                                    "
                                                    :label="
                                                        $t(
                                                            'users:lastExamination'
                                                        )
                                                    "
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="
                                                    getUsersStaffModal.lastExamination
                                                "
                                                @input="dataThree = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-menu
                                            v-model="dataFour"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    clearable
                                                    @click:clear="
                                                        getUsersStaffModal.endExamination = null
                                                    "
                                                    v-model="
                                                        getUsersStaffModal.endExamination
                                                    "
                                                    :label="
                                                        $t(
                                                            'users:endExamination'
                                                        )
                                                    "
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="
                                                    getUsersStaffModal.endExamination
                                                "
                                                @input="dataFour = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-menu
                                            v-model="dataFive"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    v-model="
                                                        getUsersStaffModal.startBhp
                                                    "
                                                    :label="
                                                        $t('users:startBhp')
                                                    "
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    clearable
                                                    @click:clear="
                                                        getUsersStaffModal.startBhp = null
                                                    "
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="
                                                    getUsersStaffModal.startBhp
                                                "
                                                @input="dataFive = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-menu
                                            v-model="dataSix"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    v-model="
                                                        getUsersStaffModal.endBhp
                                                    "
                                                    :label="$t('users:endBhp')"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    clearable
                                                    @click:clear="
                                                        getUsersStaffModal.endBhp = null
                                                    "
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="
                                                    getUsersStaffModal.endBhp
                                                "
                                                @input="dataSix = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-menu
                                            v-model="dataSeven"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    v-model="
                                                        getUsersStaffModal.dayOfBirth
                                                    "
                                                    :label="
                                                        $t('users:dayOfBirth')
                                                    "
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    clearable
                                                    @click:clear="
                                                        getUsersStaffModal.dayOfBirth = null
                                                    "
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="
                                                    getUsersStaffModal.dayOfBirth
                                                "
                                                @input="dataSeven = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="
                                                getUsersStaffModal.placeOfBirth
                                            "
                                            :label="$t('users:placeOfBirth')"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content step="2">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="
                                                $t(
                                                    'users:additionalInformation'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            no-resize
                                            v-model="
                                                getUsersStaffModal.needments
                                            "
                                            rows="4"
                                            :label="$t('users:needments')"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            no-resize
                                            v-model="getUsersStaffModal.comment"
                                            rows="4"
                                            :label="$t('users:comments')"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['users', 'global'] },
    data() {
        return {
            dataOne: '',
            dataTwo: '',
            dataThree: '',
            dataFour: '',
            dataFive: '',
            dataSix: '',
            dataSeven: '',
            e1: 1,
            editable: true,
            employmentBasis: [
                this.$t('users:contractOfEmployment'),
                this.$t('users:contractOfMandate'),
                this.$t('users:contractwork'),
            ],
        }
    },

    computed: {
        ...mapGetters([
            'getUsersStaffModal',
            'getUsersStaffError',
            'getRegistryDictionaries',
        ]),
    },
}
</script>
