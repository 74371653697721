<template>
    <v-container>
        <Header
            :registryTitle="getUsersRegistry.name"
            :registryUrl="'users'"
            :registryStatus="getUsersStatuses"
            :actualStatus="getUsersDetails.status"
            :recordIndex="getUsersDetails.index"
            @status-changed="changeStatus"
            :avatarData="getUsersDetails.createdBy"
        />
        <Main :items="items" :activeTab="activeTab" class="p-relative">
            <v-tab-item
                v-for="item in items"
                :key="item.id"
                slot="tabItems"
                :id="item.id"
            >
                <component :is="item.component"></component>
            </v-tab-item>
            <div slot="information">
                <Information />
                <Modal
                    :title="$t('global:edit')"
                    :open="open"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        @closeModal="open = false"
                        slot="buttons"
                        :action="'edit'"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--details">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                data-cy-users="sendEmail"
                                dark
                                v-on="on"
                                small
                                v-if="edit()"
                                @click="sandEmail()"
                                class="buttons--add mx-3"
                            >
                                <v-icon small>mdi-email-fast</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('users:sendEmail') }}</span>
                    </v-tooltip>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                data-cy-users="editInformation"
                                dark
                                v-on="on"
                                v-if="edit()"
                                small
                                @click="editUser()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('global:editEntry') }}</span>
                    </v-tooltip>
                </div>
            </div>
        </Main>
    </v-container>
</template>
<script>
import Information from './../../../components//Registries/Users/Details/Information'
import Staff from './../../../components//Registries/Users/Tabs/Staff/Details/Staff'
import Buttons from './../../../components/Registries/Users/Modal/Buttons'
import AEContent from './../../../components/Registries/Users/Modal/Content'
import store from '../../../store/index'
import { mapMutations, mapGetters, mapActions } from 'vuex'
export default {
    i18nOptions: { namespaces: ['users', 'global'] },
    components: {
        Information,
        AEContent,
        Buttons,
        Staff,
    },
    methods: {
        ...mapActions(['updateUserStatus', 'sendMail', 'fetchRolesList','fetchUserStaff']),
        ...mapMutations(['setUserModal', 'clearUsersErrors', 'clearUserModal']),
        closeModal() {
            this.clearUsersErrors()
            this.open = false
        },
        edit() {
            return this.checkPermissions('UPDATE')
        },
        changeStatus(status) {
            this.updateUserStatus({ status, id: this.getUsersDetails._id })
        },
        sandEmail() {
            if (this.getUsersDetails.isUser == true)
                this.sendMail({
                    mailBody: {
                        case: 'confirmUser',
                        mail: this.getUsersDetails.mail,
                        details: {
                            name: this.getUsersDetails.name,
                            lastname: this.getUsersDetails.lastname,
                            login: this.getUsersDetails.login,
                        },
                    },
                })
        },
        editUser() {
            this.clearUserModal()
            this.open = true
            this.fetchRolesList({
                select: 'name isDeleted',
                where: { isDeleted: false },
            })
            this.setUserModal()
        },
    },
    computed: {
        ...mapGetters([
            'getUsersStatuses',
            'getUsersDetails',
            'getUsersRegistry',
        ]),
    },
    data: () => ({
        open: false,
        activeTab: 'information',
        items: [
            {
                name: 'Kadry',
                id: 'staff',
                icon: 'mdi-badge-account-horizontal',
                component: Staff,
            },
        ],
    }),
    beforeRouteEnter(to, from, next) {
        store.commit('clearUserStaff');
        store.dispatch('fetchUserStaff',{id: to.params.id})
        store.dispatch('fetchUser', { id: to.params.id, next})
    },
}
</script>
