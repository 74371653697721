<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="3" xl="3" lg="3" md="5" sm="12" class="pa-5">
                <v-row>
                    <v-col
                        class="py-5 d-flex flex-column align-center"
                        cols="12"
                        md="12"
                    >
                        <v-avatar size="160" color="secondary">
                            <img
                                v-if="getUsersDetails.picture"
                                :src="`${path}${getUsersDetails.picture}`"
                                alt="avatar"
                            />
                            <span v-else class="white--text"
                                >{{ getUsersDetails.name[0]
                                }}{{ getUsersDetails.lastname[0] }}</span
                            >
                        </v-avatar>
                        <div class="d-flex flex-column align-center px-5 mt-4">
                            <span class="title" data-cy-users="fnln"
                                >{{ getUsersDetails.name }}
                                {{ getUsersDetails.lastname }}</span
                            >
                            <span class="grey--text" data-cy-users="position">{{
                                getUsersDetails.position
                            }}</span>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6" xl="6" lg="8" md="12" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" md="12">
                        <Separator :text="$t('users:userData')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-1" cols="12" md="4">
                        <v-text-field
                            readonly
                            v-model="getUsersDetails.name"
                            :label="$t('users:name')"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" md="4">
                        <v-text-field
                            readonly
                            v-model="getUsersDetails.lastname"
                            :label="$t('users:lastname')"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" md="4">
                        <v-text-field
                            readonly
                            v-model="getUsersDetails.position"
                            :label="$t('users:position')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-1" cols="12" md="3">
                        <v-text-field
                            readonly
                            data-cy-users="id"
                            v-model="getUsersDetails.userIndex"
                            :label="$t('users:userIndex')"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-1" cols="12" md="6">
                        <v-text-field
                            readonly
                            data-cy-users="companyEmail"
                            v-model="getUsersDetails.mail"
                            :label="`${$t('users:mail')}`"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" md="6">
                        <v-text-field
                            readonly
                            data-cy-users="privateEmail"
                            v-model="getUsersDetails.privateMail"
                            :label="$t('users:privateMail')"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" md="6">
                        <v-text-field
                            readonly
                            data-cy-users="companyPhone"
                            v-model="getUsersDetails.phone"
                            :label="$t('users:phone')"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" md="6">
                        <v-text-field
                            readonly
                            data-cy-users="privatePhone"
                            v-model="getUsersDetails.privatePhone"
                            :label="$t('users:privatePhone')"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="12">
                        <Separator :text="$t('users:accountData')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-1" cols="12" md="4">
                        <v-text-field
                            readonly
                            data-cy-users="login"
                            v-model="getUsersDetails.login"
                            :label="`${$t('users:login')}`"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" md="4">
                        <v-text-field
                            readonly
                            data-cy-users="isAdmin"
                            :value="
                                getUsersDetails.isAdmin === true
                                    ? $t('global:yes')
                                    : $t('global:no')
                            "
                            :label="`${$t('users:isAdmin')}`"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" md="4">
                        <v-text-field
                            readonly
                            data-cy-users="role"
                            :value="`${$get(getUsersDetails, 'role.name', '')}`"
                            :label="$t('users:roleName')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-1" cols="12" md="4">
                        <v-checkbox
                            data-cy-users="isUser"
                            v-model="getUsersDetails.isUser"
                            :label="$t('users:isUser')"
                            disabled
                            color="secondary"
                            hide-details
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['users'] },
    data() {
        return {
            path: `${process.env.VUE_APP_SERVER}/secured/profile/`,
        }
    },
    computed: {
        ...mapGetters(['getUsersDetails', 'getProfilePicture']),
    },
}
</script>
