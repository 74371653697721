var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"details__card"},[_c('v-col',{staticClass:"pa-5",attrs:{"cols":"6","xl":"6","lg":"8","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('users:basicInformation')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"6","md":"6","xs":"12"}},[_c('v-text-field',{attrs:{"value":_vm.getUsersStaffDetails.contract,"readonly":"","label":_vm.$t('users:contract')}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"6","md":"6","xs":"12"}},[_c('v-text-field',{attrs:{"value":_vm.getUsersStaffDetails.dayJob,"readonly":"","label":_vm.$t('users:dayJob')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"6","md":"6","xs":"12"}},[_c('v-text-field',{attrs:{"value":_vm.getUsersStaffDetails.employmentStart
                                ? _vm.$moment(
                                      _vm.getUsersStaffDetails.employmentStart
                                  ).format('YYYY-MM-DD')
                                : '',"readonly":"","label":_vm.$t('users:employmentStart')}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"6","md":"6","xs":"12"}},[_c('v-text-field',{attrs:{"value":_vm.getUsersStaffDetails.employmentEnd
                                ? _vm.$moment(
                                      _vm.getUsersStaffDetails.employmentEnd
                                  ).format('YYYY-MM-DD')
                                : '',"readonly":"","label":_vm.$t('users:employmentEnd')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"6","md":"6","xs":"12"}},[_c('v-text-field',{attrs:{"value":_vm.getUsersStaffDetails.lastExamination
                                ? _vm.$moment(
                                      _vm.getUsersStaffDetails.lastExamination
                                  ).format('YYYY-MM-DD')
                                : '',"readonly":"","label":_vm.$t('users:lastExamination')}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"6","md":"6","xs":"12"}},[_c('v-text-field',{attrs:{"value":_vm.getUsersStaffDetails.endExamination
                                ? _vm.$moment(
                                      _vm.getUsersStaffDetails.endExamination
                                  ).format('YYYY-MM-DD')
                                : '',"readonly":"","label":_vm.$t('users:endExamination')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"6","md":"6","xs":"12"}},[_c('v-text-field',{attrs:{"value":_vm.getUsersStaffDetails.startBhp
                                ? _vm.$moment(
                                      _vm.getUsersStaffDetails.startBhp
                                  ).format('YYYY-MM-DD')
                                : '',"readonly":"","label":_vm.$t('users:startBhp')}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"6","md":"6","xs":"12"}},[_c('v-text-field',{attrs:{"value":_vm.getUsersStaffDetails.endBhp
                                ? _vm.$moment(
                                      _vm.getUsersStaffDetails.endBhp
                                  ).format('YYYY-MM-DD')
                                : '',"readonly":"","label":_vm.$t('users:endBhp')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"6","md":"6","xs":"12"}},[_c('v-text-field',{attrs:{"value":_vm.getUsersStaffDetails.dayOfBirth
                                ? _vm.$moment(
                                      _vm.getUsersStaffDetails.dayOfBirth
                                  ).format('YYYY-MM-DD')
                                : '',"readonly":"","label":_vm.$t('users:dayOfBirth')}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"6","md":"6","xs":"12"}},[_c('v-text-field',{attrs:{"value":_vm.getUsersStaffDetails.placeOfBirth,"readonly":"","label":_vm.$t('users:placeOfBirth')}})],1)],1),_c('v-divider',{staticClass:"details__devider",attrs:{"vertical":""}})],1),_c('v-col',{staticClass:"pa-5 details__card",attrs:{"cols":"6","xl":"6","lg":"8","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('users:additionalInformation')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"6","md":"12","xs":"12"}},[_c('v-textarea',{attrs:{"value":_vm.getUsersStaffDetails.needments,"readonly":"","no-resize":"","rows":"3","label":_vm.$t('users:needments')}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"6","md":"12","xs":"12"}},[_c('v-textarea',{attrs:{"value":_vm.getUsersStaffDetails.comment,"readonly":"","no-resize":"","rows":"3","label":_vm.$t('users:comments')}})],1)],1)],1)],1),_c('Buttons')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }