var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getUsersStaffError.stepOne.validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('users:basicInformation')))]),_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getUsersStaffError.stepTwo.validation,
                            ],"editable":"","step":"2"}},[_vm._v(_vm._s(_vm.$t('users:additionalInformation')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('users:basicInformation')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"data-cy":"contract","items":_vm.getRegistryDictionaries.contract
                                                ? _vm.getRegistryDictionaries.contract
                                                : [],"label":_vm.$t('users:contract'),"item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(
                                            _vm.getUsersStaffModal.contract
                                        ),callback:function ($$v) {_vm.$set(_vm.getUsersStaffModal, "contract", $$v)},expression:"\n                                            getUsersStaffModal.contract\n                                        "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"data-cy":"dayJob","items":_vm.getRegistryDictionaries.dayJob
                                                ? _vm.getRegistryDictionaries.dayJob
                                                : [],"label":_vm.$t('users:dayJob'),"item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(_vm.getUsersStaffModal.dayJob),callback:function ($$v) {_vm.$set(_vm.getUsersStaffModal, "dayJob", $$v)},expression:"getUsersStaffModal.dayJob"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":_vm.$t(
                                                        'users:employmentStart'
                                                    ),"prepend-icon":"mdi-calendar","readonly":""},on:{"click:clear":function($event){_vm.getUsersStaffModal.employmentStart = null}},model:{value:(
                                                    _vm.getUsersStaffModal.employmentStart
                                                ),callback:function ($$v) {_vm.$set(_vm.getUsersStaffModal, "employmentStart", $$v)},expression:"\n                                                    getUsersStaffModal.employmentStart\n                                                "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dataOne),callback:function ($$v) {_vm.dataOne=$$v},expression:"dataOne"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dataOne = false}},model:{value:(
                                                _vm.getUsersStaffModal.employmentStart
                                            ),callback:function ($$v) {_vm.$set(_vm.getUsersStaffModal, "employmentStart", $$v)},expression:"\n                                                getUsersStaffModal.employmentStart\n                                            "}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":_vm.$t(
                                                        'users:employmentEnd'
                                                    ),"prepend-icon":"mdi-calendar","readonly":""},on:{"click:clear":function($event){_vm.getUsersStaffModal.employmentEnd = null}},model:{value:(
                                                    _vm.getUsersStaffModal.employmentEnd
                                                ),callback:function ($$v) {_vm.$set(_vm.getUsersStaffModal, "employmentEnd", $$v)},expression:"\n                                                    getUsersStaffModal.employmentEnd\n                                                "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dataTwo),callback:function ($$v) {_vm.dataTwo=$$v},expression:"dataTwo"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dataTwo = false}},model:{value:(
                                                _vm.getUsersStaffModal.employmentEnd
                                            ),callback:function ($$v) {_vm.$set(_vm.getUsersStaffModal, "employmentEnd", $$v)},expression:"\n                                                getUsersStaffModal.employmentEnd\n                                            "}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":_vm.$t(
                                                        'users:lastExamination'
                                                    ),"prepend-icon":"mdi-calendar","readonly":""},on:{"click:clear":function($event){_vm.getUsersStaffModal.lastExamination = null}},model:{value:(
                                                    _vm.getUsersStaffModal.lastExamination
                                                ),callback:function ($$v) {_vm.$set(_vm.getUsersStaffModal, "lastExamination", $$v)},expression:"\n                                                    getUsersStaffModal.lastExamination\n                                                "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dataThree),callback:function ($$v) {_vm.dataThree=$$v},expression:"dataThree"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dataThree = false}},model:{value:(
                                                _vm.getUsersStaffModal.lastExamination
                                            ),callback:function ($$v) {_vm.$set(_vm.getUsersStaffModal, "lastExamination", $$v)},expression:"\n                                                getUsersStaffModal.lastExamination\n                                            "}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":_vm.$t(
                                                        'users:endExamination'
                                                    ),"prepend-icon":"mdi-calendar","readonly":""},on:{"click:clear":function($event){_vm.getUsersStaffModal.endExamination = null}},model:{value:(
                                                    _vm.getUsersStaffModal.endExamination
                                                ),callback:function ($$v) {_vm.$set(_vm.getUsersStaffModal, "endExamination", $$v)},expression:"\n                                                    getUsersStaffModal.endExamination\n                                                "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dataFour),callback:function ($$v) {_vm.dataFour=$$v},expression:"dataFour"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dataFour = false}},model:{value:(
                                                _vm.getUsersStaffModal.endExamination
                                            ),callback:function ($$v) {_vm.$set(_vm.getUsersStaffModal, "endExamination", $$v)},expression:"\n                                                getUsersStaffModal.endExamination\n                                            "}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('users:startBhp'),"prepend-icon":"mdi-calendar","readonly":"","clearable":""},on:{"click:clear":function($event){_vm.getUsersStaffModal.startBhp = null}},model:{value:(
                                                    _vm.getUsersStaffModal.startBhp
                                                ),callback:function ($$v) {_vm.$set(_vm.getUsersStaffModal, "startBhp", $$v)},expression:"\n                                                    getUsersStaffModal.startBhp\n                                                "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dataFive),callback:function ($$v) {_vm.dataFive=$$v},expression:"dataFive"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dataFive = false}},model:{value:(
                                                _vm.getUsersStaffModal.startBhp
                                            ),callback:function ($$v) {_vm.$set(_vm.getUsersStaffModal, "startBhp", $$v)},expression:"\n                                                getUsersStaffModal.startBhp\n                                            "}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('users:endBhp'),"prepend-icon":"mdi-calendar","readonly":"","clearable":""},on:{"click:clear":function($event){_vm.getUsersStaffModal.endBhp = null}},model:{value:(
                                                    _vm.getUsersStaffModal.endBhp
                                                ),callback:function ($$v) {_vm.$set(_vm.getUsersStaffModal, "endBhp", $$v)},expression:"\n                                                    getUsersStaffModal.endBhp\n                                                "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dataSix),callback:function ($$v) {_vm.dataSix=$$v},expression:"dataSix"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dataSix = false}},model:{value:(
                                                _vm.getUsersStaffModal.endBhp
                                            ),callback:function ($$v) {_vm.$set(_vm.getUsersStaffModal, "endBhp", $$v)},expression:"\n                                                getUsersStaffModal.endBhp\n                                            "}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('users:dayOfBirth'),"prepend-icon":"mdi-calendar","readonly":"","clearable":""},on:{"click:clear":function($event){_vm.getUsersStaffModal.dayOfBirth = null}},model:{value:(
                                                    _vm.getUsersStaffModal.dayOfBirth
                                                ),callback:function ($$v) {_vm.$set(_vm.getUsersStaffModal, "dayOfBirth", $$v)},expression:"\n                                                    getUsersStaffModal.dayOfBirth\n                                                "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dataSeven),callback:function ($$v) {_vm.dataSeven=$$v},expression:"dataSeven"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dataSeven = false}},model:{value:(
                                                _vm.getUsersStaffModal.dayOfBirth
                                            ),callback:function ($$v) {_vm.$set(_vm.getUsersStaffModal, "dayOfBirth", $$v)},expression:"\n                                                getUsersStaffModal.dayOfBirth\n                                            "}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('users:placeOfBirth')},model:{value:(
                                            _vm.getUsersStaffModal.placeOfBirth
                                        ),callback:function ($$v) {_vm.$set(_vm.getUsersStaffModal, "placeOfBirth", $$v)},expression:"\n                                            getUsersStaffModal.placeOfBirth\n                                        "}})],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t(
                                                'users:additionalInformation'
                                            )}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"no-resize":"","rows":"4","label":_vm.$t('users:needments')},model:{value:(
                                            _vm.getUsersStaffModal.needments
                                        ),callback:function ($$v) {_vm.$set(_vm.getUsersStaffModal, "needments", $$v)},expression:"\n                                            getUsersStaffModal.needments\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"no-resize":"","rows":"4","label":_vm.$t('users:comments')},model:{value:(_vm.getUsersStaffModal.comment),callback:function ($$v) {_vm.$set(_vm.getUsersStaffModal, "comment", $$v)},expression:"getUsersStaffModal.comment"}})],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }