<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="getUsersStep.step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getUsersError.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{ $t('users:userData') }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                            <v-stepper-step
                                v-if="permission()"
                                :rules="[
                                    () => getUsersError.stepTwo.validation,
                                ]"
                                :editable="permission()"
                                step="2"
                                >{{ $t('users:accountData') }}</v-stepper-step
                            >
                            <v-divider v-if="permission()"></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="$t('users:userData')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="py-1" cols="12" md="3">
                                        <v-text-field
                                            data-cy-users="firstName"
                                            v-model="getUsersModal.name"
                                            :label="`${$t('users:name')}*`"
                                            :error-messages="
                                                getUsersError.stepOne.fields
                                                    .name
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col class="py-1" cols="12" md="4">
                                        <v-text-field
                                            data-cy-users="lastName"
                                            v-model="getUsersModal.lastname"
                                            :error-messages="
                                                getUsersError.stepOne.fields
                                                    .lastname
                                            "
                                            :label="`${$t('users:lastname')}*`"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col class="py-1" cols="12" md="2">
                                        <v-text-field
                                            data-cy-users="id"
                                            v-model="getUsersModal.userIndex"
                                            :label="$t('users:userIndex')"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col class="py-1" cols="12" md="6">
                                        <v-select
                                            data-cy-users="position"
                                            :items="position"
                                            v-model="getUsersModal.position"
                                            :label="$t('users:position')"
                                            item-value="field"
                                        >
                                            <template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col class="py-1" cols="12" md="6">
                                        <v-text-field
                                            data-cy-users="companyEmail"
                                            v-model="getUsersModal.mail"
                                            :error-messages="
                                                getUsersError.stepOne.fields
                                                    .mail
                                            "
                                            :label="`${$t('users:mail')}*`"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col class="py-1" cols="12" md="6">
                                        <v-text-field
                                            data-cy-users="privateEmail"
                                            v-model="getUsersModal.privateMail"
                                            :label="$t('users:privateMail')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col class="py-1" cols="12" md="6">
                                        <v-text-field
                                            data-cy-users="companyPhone"
                                            v-model="getUsersModal.phone"
                                            :label="$t('users:phone')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col class="py-1" cols="12" md="6">
                                        <v-text-field
                                            data-cy-users="privatePhone"
                                            v-model="getUsersModal.privatePhone"
                                            :label="$t('users:privatePhone')"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content step="2">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="$t('users:accountData')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pb-5 pt-0" cols="12" md="6">
                                        <v-checkbox
                                            data-cy-users="isUser"
                                            v-model="getUsersModal.isUser"
                                            @change="
                                                isUser(getUsersModal.isUser)
                                            "
                                            :label="$t('users:isUser')"
                                            color="secondary"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="py-1" cols="12" md="6">
                                        <v-text-field
                                            data-cy-users="login"
                                            v-if="getUsersModal.isUser"
                                            v-model="getUsersModal.login"
                                            :error-messages="
                                                getUsersError.stepTwo.fields
                                                    .login
                                            "
                                            :label="`${$t('users:login')}*`"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col class="py-1" cols="12" md="6">
                                        <v-select
                                            data-cy-users="isAdmin"
                                            :items="options"
                                            v-if="getUsersModal.isUser"
                                            :error-messages="
                                                getUsersError.stepTwo.fields
                                                    .isAdmin
                                            "
                                            v-model="getUsersModal.isAdmin"
                                            :label="`${$t('users:isAdmin')}*`"
                                        >
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="py-1" cols="12" md="6">
                                        <v-select
                                            data-cy-users="role"
                                            :items="roles"
                                            item-text="name"
                                            item-value="_id"
                                            :error-messages="
                                                getUsersError.stepTwo.fields
                                                    .role
                                            "
                                            v-model="getUsersModal.role"
                                            :label="`${$t('users:roleName')}*`"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="py-1" cols="12" md="6">
                                        <v-select
                                            :items="specialRolesList"
                                            item-text="field"
                                            item-value="value"
                                            multiple
                                            v-model="
                                                getUsersModal.specialRoleName
                                            "
                                            :label="`${$t(
                                                'users:specialRoleName'
                                            )}*`"
                                            ><template v-slot:selection="data">
                                                <span>{{
                                                    data.item.field
                                                }}</span>
                                                <span
                                                    class="mr-1"
                                                    v-if="
                                                        getUsersModal.specialRoleName &&
                                                        getUsersModal
                                                            .specialRoleName
                                                            .length > 1
                                                    "
                                                    >,</span
                                                >
                                            </template>
                                            <template v-slot:item="data">
                                                <span
                                                    >{{ data.item.field }}
                                                </span>
                                            </template></v-select
                                        >
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="py-1" cols="12" md="6">
                                        <v-text-field
                                            :rules="rules"
                                            :counter-value="
                                                v =>
                                                    v
                                                        ? v.length +
                                                          ` ${this.$t(
                                                              'profile:characters'
                                                          )}`
                                                        : 0 +
                                                          ` ${this.$t(
                                                              'profile:characters'
                                                          )}`
                                            "
                                            :append-icon="
                                                show1
                                                    ? 'mdi-eye'
                                                    : 'mdi-eye-off'
                                            "
                                            :class="
                                                checkLength(
                                                    getUsersModal.passwordAgain
                                                )
                                            "
                                            data-cy-users="password"
                                            v-model="getUsersModal.password"
                                            :label="`${$t('users:password')}*`"
                                            :type="show1 ? 'text' : 'password'"
                                            counter
                                            @click:append="show1 = !show1"
                                            v-if="getUsersModal.isUser"
                                            :error-messages="
                                                getUsersError.stepTwo.fields
                                                    .password
                                            "
                                        ></v-text-field>
                                    </v-col>

                                    <v-col class="py-1" cols="12" md="6">
                                        <v-text-field
                                            :rules="rules"
                                            :counter-value="
                                                v =>
                                                    v
                                                        ? v.length +
                                                          ` ${this.$t(
                                                              'profile:characters'
                                                          )}`
                                                        : 0 +
                                                          ` ${this.$t(
                                                              'profile:characters'
                                                          )}`
                                            "
                                            :class="
                                                checkLength(
                                                    getUsersModal.passwordAgain
                                                )
                                            "
                                            data-cy-users="repeatPassword"
                                            :append-icon="
                                                show1
                                                    ? 'mdi-eye'
                                                    : 'mdi-eye-off'
                                            "
                                            v-model="
                                                getUsersModal.passwordAgain
                                            "
                                            :type="show2 ? 'text' : 'password'"
                                            counter
                                            minlength="8"
                                            @click:append="show2 = !show2"
                                            v-if="getUsersModal.isUser"
                                            :error-messages="
                                                getUsersError.stepTwo.fields
                                                    .passwordAgain
                                            "
                                            :label="`${$t(
                                                'users:passwordAgain'
                                            )}*`"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['users', 'global'] },
    data() {
        return {
            date: '',
            show1: false,
            show2: false,
            editable: true,
            rules: [
                v =>
                    v
                        ? v.length == 0 ||
                          v.length >= 8 ||
                          this.$t('profile:shortPassword')
                        : true,
            ],
            options: [
                { text: this.$t('global:yes'), value: true },
                { text: this.$t('global:no'), value: false },
            ],
            specialRolesList: [
                { field: this.$t('service:technic'), value: 'technic' },
                { field: this.$t('service:logistic'), value: 'logistic' },
                {
                    field: this.$t('service:logisticDirector'),
                    value: 'logisticDirector',
                },
            ],
        }
    },

    computed: {
        ...mapGetters([
            'getUsersModal',
            'getUsersError',
            'getRegistryDictionaries',
            'getUsersStep',
            'getRolesList',
            'getProfileDetails',
        ]),
        position: {
            get() {
                if (this.getRegistryDictionaries.position)
                    return this.getRegistryDictionaries.position.sort((a, b) =>
                        a.field > b.field ? 1 : -1
                    )
                else return []
            },
        },
        // specialRoleList: {
        //     get() {
        //         if (this.getRegistryDictionaries.specialRoleName)
        //             return this.getRegistryDictionaries.specialRoleName.sort(
        //                 (a, b) => (a.field > b.field ? 1 : -1)
        //             )
        //         else return []
        //     },
        // },
        roles: {
            get() {
                return this.getRolesList.sort((a, b) =>
                    a.name > b.name ? 1 : -1
                )
            },
        },
    },
    methods: {
        ...mapMutations(['workerIsUser']),
        isUser(val) {
            this.workerIsUser(val)
        },
        checkLength(v) {
            if (v && v.length >= 8) return 'success--text'
        },
        permission() {
            if (
                this.getProfileDetails.isAdmin ||
                this.getProfileDetails.isSuperAdmin
            )
                return true
        },
    },
}
</script>
<style lang="sass">
.success--text>.v-input__control>.v-text-field__details>.v-counter
    color: green !important
</style>
