var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.getUsersStep.step),callback:function ($$v) {_vm.$set(_vm.getUsersStep, "step", $$v)},expression:"getUsersStep.step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getUsersError.stepOne.validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('users:userData')))]),_c('v-divider'),(_vm.permission())?_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getUsersError.stepTwo.validation,
                            ],"editable":_vm.permission(),"step":"2"}},[_vm._v(_vm._s(_vm.$t('users:accountData')))]):_vm._e(),(_vm.permission())?_c('v-divider'):_vm._e()],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('users:userData')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"data-cy-users":"firstName","label":`${_vm.$t('users:name')}*`,"error-messages":_vm.getUsersError.stepOne.fields
                                                .name},model:{value:(_vm.getUsersModal.name),callback:function ($$v) {_vm.$set(_vm.getUsersModal, "name", $$v)},expression:"getUsersModal.name"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"data-cy-users":"lastName","error-messages":_vm.getUsersError.stepOne.fields
                                                .lastname,"label":`${_vm.$t('users:lastname')}*`},model:{value:(_vm.getUsersModal.lastname),callback:function ($$v) {_vm.$set(_vm.getUsersModal, "lastname", $$v)},expression:"getUsersModal.lastname"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"data-cy-users":"id","label":_vm.$t('users:userIndex')},model:{value:(_vm.getUsersModal.userIndex),callback:function ($$v) {_vm.$set(_vm.getUsersModal, "userIndex", $$v)},expression:"getUsersModal.userIndex"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"data-cy-users":"position","items":_vm.position,"label":_vm.$t('users:position'),"item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(_vm.getUsersModal.position),callback:function ($$v) {_vm.$set(_vm.getUsersModal, "position", $$v)},expression:"getUsersModal.position"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"data-cy-users":"companyEmail","error-messages":_vm.getUsersError.stepOne.fields
                                                .mail,"label":`${_vm.$t('users:mail')}*`},model:{value:(_vm.getUsersModal.mail),callback:function ($$v) {_vm.$set(_vm.getUsersModal, "mail", $$v)},expression:"getUsersModal.mail"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"data-cy-users":"privateEmail","label":_vm.$t('users:privateMail')},model:{value:(_vm.getUsersModal.privateMail),callback:function ($$v) {_vm.$set(_vm.getUsersModal, "privateMail", $$v)},expression:"getUsersModal.privateMail"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"data-cy-users":"companyPhone","label":_vm.$t('users:phone')},model:{value:(_vm.getUsersModal.phone),callback:function ($$v) {_vm.$set(_vm.getUsersModal, "phone", $$v)},expression:"getUsersModal.phone"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"data-cy-users":"privatePhone","label":_vm.$t('users:privatePhone')},model:{value:(_vm.getUsersModal.privatePhone),callback:function ($$v) {_vm.$set(_vm.getUsersModal, "privatePhone", $$v)},expression:"getUsersModal.privatePhone"}})],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('users:accountData')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-5 pt-0",attrs:{"cols":"12","md":"6"}},[_c('v-checkbox',{attrs:{"data-cy-users":"isUser","label":_vm.$t('users:isUser'),"color":"secondary","hide-details":""},on:{"change":function($event){return _vm.isUser(_vm.getUsersModal.isUser)}},model:{value:(_vm.getUsersModal.isUser),callback:function ($$v) {_vm.$set(_vm.getUsersModal, "isUser", $$v)},expression:"getUsersModal.isUser"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6"}},[(_vm.getUsersModal.isUser)?_c('v-text-field',{attrs:{"data-cy-users":"login","error-messages":_vm.getUsersError.stepTwo.fields
                                                .login,"label":`${_vm.$t('users:login')}*`},model:{value:(_vm.getUsersModal.login),callback:function ($$v) {_vm.$set(_vm.getUsersModal, "login", $$v)},expression:"getUsersModal.login"}}):_vm._e()],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6"}},[(_vm.getUsersModal.isUser)?_c('v-select',{attrs:{"data-cy-users":"isAdmin","items":_vm.options,"error-messages":_vm.getUsersError.stepTwo.fields
                                                .isAdmin,"label":`${_vm.$t('users:isAdmin')}*`},model:{value:(_vm.getUsersModal.isAdmin),callback:function ($$v) {_vm.$set(_vm.getUsersModal, "isAdmin", $$v)},expression:"getUsersModal.isAdmin"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"data-cy-users":"role","items":_vm.roles,"item-text":"name","item-value":"_id","error-messages":_vm.getUsersError.stepTwo.fields
                                                .role,"label":`${_vm.$t('users:roleName')}*`},model:{value:(_vm.getUsersModal.role),callback:function ($$v) {_vm.$set(_vm.getUsersModal, "role", $$v)},expression:"getUsersModal.role"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.specialRolesList,"item-text":"field","item-value":"value","multiple":"","label":`${_vm.$t(
                                            'users:specialRoleName'
                                        )}*`},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.field))]),(
                                                    _vm.getUsersModal.specialRoleName &&
                                                    _vm.getUsersModal
                                                        .specialRoleName
                                                        .length > 1
                                                )?_c('span',{staticClass:"mr-1"},[_vm._v(",")]):_vm._e()]}},{key:"item",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.field)+" ")])]}}]),model:{value:(
                                            _vm.getUsersModal.specialRoleName
                                        ),callback:function ($$v) {_vm.$set(_vm.getUsersModal, "specialRoleName", $$v)},expression:"\n                                            getUsersModal.specialRoleName\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6"}},[(_vm.getUsersModal.isUser)?_c('v-text-field',{class:_vm.checkLength(
                                                _vm.getUsersModal.passwordAgain
                                            ),attrs:{"rules":_vm.rules,"counter-value":v =>
                                                v
                                                    ? v.length +
                                                      ` ${this.$t(
                                                          'profile:characters'
                                                      )}`
                                                    : 0 +
                                                      ` ${this.$t(
                                                          'profile:characters'
                                                      )}`,"append-icon":_vm.show1
                                                ? 'mdi-eye'
                                                : 'mdi-eye-off',"data-cy-users":"password","label":`${_vm.$t('users:password')}*`,"type":_vm.show1 ? 'text' : 'password',"counter":"","error-messages":_vm.getUsersError.stepTwo.fields
                                                .password},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.getUsersModal.password),callback:function ($$v) {_vm.$set(_vm.getUsersModal, "password", $$v)},expression:"getUsersModal.password"}}):_vm._e()],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6"}},[(_vm.getUsersModal.isUser)?_c('v-text-field',{class:_vm.checkLength(
                                                _vm.getUsersModal.passwordAgain
                                            ),attrs:{"rules":_vm.rules,"counter-value":v =>
                                                v
                                                    ? v.length +
                                                      ` ${this.$t(
                                                          'profile:characters'
                                                      )}`
                                                    : 0 +
                                                      ` ${this.$t(
                                                          'profile:characters'
                                                      )}`,"data-cy-users":"repeatPassword","append-icon":_vm.show1
                                                ? 'mdi-eye'
                                                : 'mdi-eye-off',"type":_vm.show2 ? 'text' : 'password',"counter":"","minlength":"8","error-messages":_vm.getUsersError.stepTwo.fields
                                                .passwordAgain,"label":`${_vm.$t(
                                            'users:passwordAgain'
                                        )}*`},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(
                                            _vm.getUsersModal.passwordAgain
                                        ),callback:function ($$v) {_vm.$set(_vm.getUsersModal, "passwordAgain", $$v)},expression:"\n                                            getUsersModal.passwordAgain\n                                        "}}):_vm._e()],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }